import React, { useEffect, useState } from "react";
import logo from "./assets/logo.png";
import { IoClose } from "react-icons/io5";
import { TbLayoutDistributeVertical } from "react-icons/tb";

import { Formik, Field, Form } from "formik";
import * as Yup from "yup";

import { login, register } from "../../services/auth.service";
import { TUser } from "../../store/types";
import useAuthStore from "../../store/auth.store";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { successImg } from "../../assets/images";
import { AxiosError } from "axios";
import { useGetUser } from "../../hooks/useGetUser";
import ReactInputMask from "react-input-mask";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
const Register: React.FC<{ isReg: boolean }> = ({ isReg }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { setUser } = useAuthStore();
  const [auth, setAuth] = useState<string>(isReg ? "signup" : "signin");
  const { isAuthenticated } = useGetUser();
  useEffect(() => {
    if (isAuthenticated && !redirect) {
      navigate("/");
    }
  }, [isAuthenticated]);
  useEffect(() => {
    if (pathname === "/login") {
      setAuth("signin");
    } else {
      setAuth("signup");
    }
  }, [pathname]);
  const [loading, setLoading] = useState<boolean>(false);
  const [successful, setSuccessful] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [isShowPassword, setIsShowPassword] = useState(false);
  type initialProps = {
    firstname?: string;
    phone?: string;
    password?: string;
  };

  const initialValues: initialProps = {
    firstname: "",
    phone: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    ...(isReg && {
      firstname: Yup.string()
        .test(
          "len",
          "The firstname must be between 2 and 20 characters.",
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (val: any) =>
            val && val.toString().length >= 2 && val.toString().length <= 20,
        )
        .required("This field is required!"),
    }),
    phone: Yup.string()
      .matches(/^\+\d{3}\s\d{2}\s\d{3}-\d{2}-\d{2}$/, "Invalid phone number")
      .required("This field is required!"),
    password: Yup.string()
    .min(6, ({ min }) => `Password must be at least ${min} characters`)
    .max(40, ({ max }) => `Password must not exceed ${max} characters`)
    .when('$submitted', (submitted, schema) =>
      submitted ? schema.required('This field is required!') : schema,
    ),
  });
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get("redirect") || "";
  const handleRegister = async (formValue: TUser) => {
    setLoading(true);
    const { firstname, phone, password } = formValue;
    try {
      const response = isReg
        ? await register(firstname!, phone?.replace(/[+\s-]/g, ""), password)
        : await login(phone?.replace(/[+\s-]/g, "")!, password!);

      setSuccessful(true);
      setUser(response.data);

      setLoading(false);
      if (redirect) {
        navigate("/basket/orderProcess");
      } else {
        navigate("/panel");
      }
    } catch (e) {
      const error = e as AxiosError;
      const resMessage: string =
        (error?.response?.data as { message?: string }).message?.toString() ||
        error.message ||
        error.toString();

      setMessage(resMessage);
      setSuccessful(false);
      setLoading(false);
    }
  };
  return (
    <div className="min-h-screen">
      {successful && isReg && <Succesfull />}
      <Link
        to={"/"}
        className="flex items-center justify-between border-b-2 p-[14px]"
      >
        <img src={logo} alt="12" />
        {/* <p className='flex items-center gap-[6px] cursor-pointer'>
          Русский <FaChevronDown />
        </p> */}
      </Link>
      <div className="m-auto my-14 flex h-full max-w-[371px] flex-col xs:my-7">
        <h1 className="text-center font-['Inter'] text-4xl font-medium not-italic leading-tight tracking-[-0.36px] text-[#000] xs:text-[24px]">
          {auth === "signup" ? "Регистрация" : "Войти"}
        </h1>
        <p className="mt-3 text-center  font-['Inter'] text-base font-medium not-italic leading-normal tracking-[-0.16px] text-blue-gray-200 xs:mt-2">
          Введите информацию
        </p>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleRegister}
        >
          {({ errors, touched, handleChange }) => {
            return (
              <Form>
                <div className="mb-4 mt-10 flex flex-col gap-6 xs:mt-5 xs:gap-4 xs:px-4">
                  {auth === "signup" && (
                    <div className="form-group">
                      <label htmlFor="firstname">Ваше имя</label>
                      <Field
                        name="firstname"
                        placeholder="Ваше имя"
                        type="text"
                        className={`bg-[#F8F8F8] ${
                          errors.firstname &&
                          touched.firstname &&
                          "border border-red-500"
                        } mt-3 h-14  w-full rounded-[10px] pl-[14px] font-['Inter'] text-base font-normal not-italic tracking-[-0.26px] text-[#989898] outline-none xs:mt-2`}
                      />
                    </div>
                  )}

                  <div className="form-group">
                    <label htmlFor="phone">Телефон номер</label>
                    <ReactInputMask
                      mask="+\9\9\8 99 999-99-99"
                      id={"phone"}
                      type={"tel"}
                      name="phone"
                      onChange={handleChange}
                      className={`bg-[#F8F8F8] ${
                        errors.phone && touched.phone && "border border-red-500"
                      } mt-3 h-14  w-full rounded-[10px] pl-[14px] font-['Inter'] text-base font-normal not-italic tracking-[-0.26px] text-[#989898] outline-none xs:mt-2`}
                      alwaysShowMask={true}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="password">Введите пароль</label>
                    <div
                      className={`bg-[#F8F8F8] ${
                        errors.password &&
                        touched.password &&
                        "border border-red-500"
                      } mt-3 flex  h-14 w-full items-center rounded-[10px] pl-[14px] pr-4 font-['Inter'] text-base font-normal not-italic tracking-[-0.26px] text-[#989898] xs:mt-2`}
                    >
                      <Field
                        className="w-full bg-transparent outline-none"
                        name="password"
                        type={`${isShowPassword ? "text" : "password"}`}
                        placeholder="Введите пароль"
                      />
                      {isShowPassword && (
                        <FaEye
                          className="cursor-pointer"
                          onClick={() => {
                            setIsShowPassword(false);
                          }}
                        />
                      )}
                      {!isShowPassword && (
                        <FaEyeSlash
                          className="cursor-pointer"
                          onClick={() => {
                            setIsShowPassword(true);
                          }}
                        />
                      )}
                    </div>
                  </div>

                  <div className="form-group">
                    <button
                      type="submit"
                      className="w-full rounded-[32px] bg-[#08A284] p-[12px_24px] font-['Inter'] text-lg font-medium not-italic leading-normal tracking-[-0.18px] text-white xs:mt-[20px]"
                    >
                      {loading
                        ? "загрузка..."
                        : isReg
                          ? "Регистрация"
                          : "Войти"}
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
        {message && (
          <p className="mt-[4px] rounded-xl border-2 border-dashed border-[#FF3535] bg-[#FFF5F5] p-[16px] font-['Inter'] text-base font-normal not-italic leading-6 tracking-[-0.16px] text-[#111]">
            {message}
          </p>
        )}

        <div className="mt-4 flex items-center justify-center gap-[10px] xxs:flex-wrap xxs:px-1">
          <p className="font-['Inter'] text-base not-italic leading-normal tracking-[-0.16px] text-[#08A284]">
            {auth === "signup" ? "  У вас есть аккаунт?" : "У вас нет аккаунт?"}
          </p>
          <p className="cursor-pointer font-['Inter'] text-base not-italic leading-normal tracking-[-0.16px] text-[#08A284] underline">
            {auth === "signup" ? (
              <Link to={`/login`} onClick={() => setAuth("signin")}>
                Войти
              </Link>
            ) : (
              <Link to={`/register`} onClick={() => setAuth("signup")}>
                Регистрация
              </Link>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Register;

const Succesfull = () => {
  return (
    <div className="fixed bottom-0 left-0 right-0 top-0 flex items-center justify-center bg-black/70">
      <div className="rounded-lg bg-white p-8">
        <div className="flex justify-end">
          <button className="rounded-full bg-[#F8F8F8] p-4 hover:bg-[#aba5a5]">
            <IoClose />
          </button>
        </div>

        <div className="flex flex-col items-center">
          <img src={successImg} alt="success" />
          <h3 className="text-[#1C1C1C text-lg font-bold">Поздравляем</h3>
          <p className="my-4">Вы успешно зарегистрировались</p>
          <p className="text-sm font-thin">
            Теперь вы можете свободно заказывать товары
          </p>
          <Link
            className="mt-4 flex w-full items-center justify-center gap-2 rounded-lg bg-[#08A284] p-4 py-2 text-white"
            to={"/"}
          >
            <TbLayoutDistributeVertical />
            Посмотреть продуктов
          </Link>
        </div>
      </div>
    </div>
  );
};
