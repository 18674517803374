function PageLoader({ className }: { className?: string }) {
  const allItemStyle = `absolute top-[40%] w-[13px] h-[13px] rounded-full bg-[#08a284]`;
  return (
    <div
      className={`lds-ellipsis my-auto mx-auto relative w-[80px] ${
        className ? className : "h-[100px]"
      }`}
    >
      <div className={`${allItemStyle} left-[8px]`}></div>
      <div className={`${allItemStyle} left-[8px]`}></div>
      <div className={`${allItemStyle} left-[32px]`}></div>
      <div className={`${allItemStyle} left-[56px]`}></div>
    </div>
  );
}

export default PageLoader;
