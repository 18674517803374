import { createBrowserRouter, RouterProvider } from "react-router-dom"; // Import lazy correctly
import Layout from "./components/Layout";
import ErrorPage from "./components/ErrorPage";
import Auth from "./pages/auth/Auth";
import React, { Suspense, useEffect } from "react";
import PageLoader from "./components/loader/PageLoader";
import { useStore } from "./hooks/store";
const Home = React.lazy(() => import("./pages/home"));
const About = React.lazy(() => import("./pages/about/About"));
const Product = React.lazy(() => import("./pages/product/Product"));
const Categories = React.lazy(() => import("./pages/category"));

const Storage = React.lazy(() => import("./pages/storage/Storage"));
const SelfOrder = React.lazy(() => import("./pages/product/SelfOrder"));
const SavedProducts = React.lazy(() => import("./pages/product/SavedProducts"));
const Admin = React.lazy(() => import("./pages/admin"));
const Profile = React.lazy(() => import("./pages/profile"));
const Suppliers = React.lazy(() => import("./pages/Suppliers"));
const Basket = React.lazy(() => import("./pages/basket"));
const Search = React.lazy(() => import("./pages/search"));
const OrderCar = React.lazy(() => import("./pages/order-car"));

// Test
const router = createBrowserRouter([
  {
    element: <Layout />,
    path: "/",
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/product/:id",
        element: <Product />,
      },
      {
        path: "/categories/*",
        element: <Categories />,
      },
      {
        path: "/suppliers/*",
        element: <Suppliers />,
      },
      {
        path: "/basket/*",
        element: <Basket />,
      },
      {
        path: "/storage",
        element: <Storage />,
      },
      {
        path: "/basket/*",
        element: <Basket />,
      },
      {
        path: "/selfprocess",
        element: <SelfOrder />,
      },
      {
        path: "/saved",
        element: <SavedProducts />,
      },
      {
        path: "/search",
        element: <Search />,
      },
      {
        path: "/orderCar",
        element: <OrderCar />,
      },
      {
        path: "*",
        element: <ErrorPage />,
      },
    ],
  },
  {
    element: <Admin />,
    path: "/admin/*",
  },
  {
    element: <Profile />,
    path: "/panel/*",
  },
  {
    path: "/login",
    element: <Auth isReg={false} />,
  },
  {
    path: "/register",
    element: <Auth isReg={true} />,
  },
]);
function App() {
  const setBasketitems = useStore((state) => state.setBasketitems);
  const basketItemsString = localStorage.getItem("basketItems");
  useEffect(() => {
    if (basketItemsString !== null) {
      const basketItems = JSON.parse(basketItemsString);
      setBasketitems(basketItems);
    }
  }, []);
  return (
    <Suspense fallback={<PageLoader />}>
      <RouterProvider router={router} />
    </Suspense>
  );
}
export default App;
