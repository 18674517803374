import React from "react";
// import Header from "./header/Header";
import { Outlet } from "react-router-dom";
const HeaderTest = React.lazy(() => import("./HeaderTest/HeaderTest"));
const BottomHeader = React.lazy(() => import("./BottomHeader/BottomHeader"));
const ScrollToTopButton = React.lazy(
  () => import("../pages/home/pages/ScrollToTopButton"),
);
const Footer = React.lazy(() => import("./Footer"));
export default function Layout() {
  return (
    <>
      {/* <Header /> */}
      <HeaderTest />
      <div className="hidden xs:flex">
        <BottomHeader />
      </div>
      <Outlet />
      <ScrollToTopButton />
      <Footer />
    </>
  );
}
