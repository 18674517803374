import { create } from "zustand";
import { IBusketCarts, IProduct } from "../@types";

type Store = {
  basketItems: IBusketCarts[];
  isDelivery: boolean;
  deliverySum: number;
  deliveryId: string;
  inc: (value: IProduct | null | undefined, amount: number | any) => void;
  changeDelivry: (value: boolean) => void;
  changeDelivrySum: (value: number) => void;
  changeDelivryId: (value: string) => void;
  setBasketitems: (value: any) => void;
};
let basketItems: string[] = [];

export const useStore = create<Store>((set) => ({
  basketItems: [],
  isDelivery: true,
  deliverySum: 0,
  deliveryId: "",
  inc: (key, amount) =>
    set((state: any) => {
      if (key === null) {
        localStorage.setItem("basketItems", JSON.stringify(basketItems));
        return { ...state, basketItems: [] };
      } else {
        const existingItemIndex = state.basketItems.findIndex(
          (item: IBusketCarts) => item?.card?._id === key?._id,
        );

        // If the item already exists, remove it from the cart
        if (existingItemIndex !== -1) {
          const updatedItems = [...state.basketItems];
          if (amount === 0) {
            updatedItems.splice(existingItemIndex, 1);
          } else {
            updatedItems[existingItemIndex].amount = amount;
          }
          localStorage.setItem("basketItems", JSON.stringify(updatedItems));
          return { ...state, basketItems: updatedItems };
        } else {
          // If the item does not exist, add it to the cart with the specified amount
          localStorage.setItem(
            "basketItems",
            JSON.stringify([
              ...state.basketItems,
              { card: key, amount: amount },
            ]),
          );
          return {
            ...state,
            basketItems: [...state.basketItems, { card: key, amount: amount }],
          };
        }
      }
    }),
  changeDelivry: (value) =>
    set((state): any => {
      return { ...state, isDelivery: value };
    }),
  changeDelivrySum: (value) =>
    set((state): any => {
      return { ...state, deliverySum: value };
    }),
  changeDelivryId: (value) =>
    set((state): any => {
      return { ...state, deliveryId: value };
    }),
  setBasketitems: (value) =>
    set((state): any => {
      return { ...state, basketItems: value };
    }),
}));
