import { AxiosResponse } from "axios";
import instance from "./api.service";

export const register = (
  firstname?: string, // Declare firstname as a string
  phone?: string, // Declare phone as a string
  password?: string // Declare password as a string
): Promise<AxiosResponse> => {
  return instance.post("/user/signup", {
    firstname,
    phone,
    password,
  });
};

export const login = (
  phone: string, // Declare phone as a string
  password: string // Declare password as a string
): Promise<AxiosResponse> => {
  return instance.post("/user/signin", {
    phone,
    password,
  });
};
export const adminlogin = (
  phone: string, // Declare phone as a string
  password: string // Declare password as a string
): Promise<AxiosResponse> => {
  return instance({
    url: `/admin/login`,
    method: "POST",
    data: { phone, password },
  });
};
