import { create } from "zustand";
import { IOrganization, TData, TToken, TUser } from "../@types";

interface AuthStore {
  user: TUser | null;
  isAuthenticated: boolean;
  token: TToken;
  organization: IOrganization | null;
  setUser: (data: TData) => void;
  setUserData: (data: TUser) => void;
  setOrganization: (data: IOrganization) => void;
  logout: () => void;
}

const useAuthStore = create<AuthStore>((set) => ({
  user: null,
  isAuthenticated: false,
  token: localStorage.getItem("token") as TToken | undefined,
  organization: null,
  setUser: (data: TData) => {
    data?.token && localStorage.setItem("token", data.token as string);

    set({
      user: data.user,
      isAuthenticated: true,
      ...(data?.token && { token: data.token }),
      ...(data?.organization && { organization: data.organization }),
    });
  },

  setOrganization: (data: IOrganization) => set({ organization: data }),
  setUserData: (data: TUser) => set({ user: data }),
  logout: () => {
    localStorage.removeItem("token");
    localStorage.removeItem("basketItems");
    set({
      user: undefined,
      isAuthenticated: false,
      token: undefined,
      organization: null,
    });
  },
}));

export default useAuthStore;
